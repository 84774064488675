import { Skeleton, Stack, Typography } from '@mui/material';
import { SSHKeyList, useViewSSHInfoNew } from 'api/sshKeys';
import { CopyButton } from '../../CopyButton';

interface SSHKeyExpandedRowProps {
  readonly siteId: string;
  readonly sshKey: SSHKeyList[number];
}

export const SSHKeyExpandedRow = ({ siteId, sshKey }: SSHKeyExpandedRowProps) => {
  const sshKeyInfo = useViewSSHInfoNew(siteId, sshKey.name!);

  const key = sshKeyInfo.data?.data.result?.key || '';

  return (
    <Stack sx={{ gridColumn: '1 / -1' }} direction="row" justifyContent="space-between" py={3}>
      <Typography variant="body2" sx={{ wordWrap: 'anywhere' }}>
        {sshKeyInfo.isLoading ? <Skeleton variant="text" width={200} /> : key || 'N/A'}
      </Typography>
      <CopyButton value={key} disabled={!key} />
    </Stack>
  );
};
