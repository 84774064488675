import { useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { useState } from 'react';
import { EmptyState } from 'component/new_design/base/EmptyState';
import {
  SSHKeyList,
  useActivateSSHKey,
  useDeactivateSSHKey,
  useDeleteSSHKey,
  useListSSHKeysNew,
} from 'api/sshKeys';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { SSHKeyExpandedRow } from './SSHKeyExpandedRow';
import { Button, Chip } from '@mui/material';
import { ImportSSHKeyDialog } from '../../SiteDetails/Advanced/ImportSSHKeyDialog';

import CodeIcon from 'component/new_design/icons/Code.svg?react';
import DeleteIcon from 'component/new_design/icons/Delete.svg?react';
import CheckCircleIcon from 'component/new_design/icons/CheckCircle.svg?react';
import CloseIcon from 'component/new_design/icons/Close.svg?react';
import PlusIcon from 'component/new_design/icons/Plus.svg?react';

interface SSHKeysTableProps {
  readonly siteId: string;
}

export function SSHKeysTable({ siteId }: SSHKeysTableProps) {
  const { t } = useTranslation();

  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState<string | null>();
  const [keyToAuthorize, setKeyToAuthorize] = useState<string | null>();
  const [keyToUnauthorize, setKeyToUnauthorize] = useState<string | null>();

  const tableState = usePaginatedListState();

  const { data, isLoading, refetch } = useListSSHKeysNew(siteId, tableState);
  const deleteSSHKey = useDeleteSSHKey(siteId);
  const activateSSHKey = useActivateSSHKey(siteId);
  const deactivateSSHKey = useDeactivateSSHKey(siteId);

  const columns: TableColumnType<SSHKeyList[number]>[] = [
    {
      label: t('name'),
      key: 'name',
    },
    {
      label: t('authorization_status'),
      key: 'authstatus',
      renderValue: key => (
        <Chip
          color={key.authstatus === 'authorized' ? 'success' : 'secondary'}
          size="medium"
          label={t(key.authstatus!)}
        />
      ),
    },
  ];

  const rowActions: TableRowActionType<SSHKeyList[number]>[] = [
    {
      icon: <CheckCircleIcon />,
      label: t('authorize'),
      onClick: key => setKeyToAuthorize(key.name),
      hidden: key => key.authstatus === 'authorized',
    },
    {
      icon: <CloseIcon />,
      label: t('unauthorize'),
      onClick: key => setKeyToUnauthorize(key.name),
      hidden: key => key.authstatus === 'not authorized',
    },
    {
      icon: <DeleteIcon />,
      label: t('delete'),
      onClick: key => setKeyToDelete(key.name),
      color: 'reds.500',
    },
  ];

  const handleDeleteConfirmed = async () => {
    if (!keyToDelete) {
      throw new Error('No key to delete');
    }
    await deleteSSHKey.mutateAsync(keyToDelete);
    refetch();
  };

  const handleAuthorizeConfirmed = async () => {
    if (!keyToAuthorize) {
      throw new Error('No key to authorize');
    }
    await activateSSHKey.mutateAsync(keyToAuthorize);
    refetch();
  };

  const handleUnauthorizeConfirmed = async () => {
    if (!keyToUnauthorize) {
      throw new Error('No key to unauthorize');
    }
    await deactivateSSHKey.mutateAsync(keyToUnauthorize);
    refetch();
  };

  return (
    <>
      <Table
        title={t('ssh_keys')}
        label={t('ssh_keys')}
        columns={columns}
        data={data?.data.result || []}
        totalRowCount={data?.data.metadata?.total || 0}
        isLoading={isLoading}
        state={tableState}
        rowActions={rowActions}
        emptyState={
          <EmptyState
            icon={<CodeIcon />}
            title={t('no_data_yet')}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsImportDialogOpen(true)}
                startIcon={<PlusIcon />}
              >
                {t('import_new_key')}
              </Button>
            }
          />
        }
        rowIdKey="name"
        renderExpandedRow={key => <SSHKeyExpandedRow siteId={siteId} sshKey={key} />}
        enablePagination
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsImportDialogOpen(true)}
          startIcon={<PlusIcon />}
        >
          {t('import_new_key')}
        </Button>
      </Table>
      {keyToDelete ? (
        <ConfirmationDialog
          title={t('delete_ssh_key')}
          description={t('delete_ssh_key_description')}
          onConfirm={handleDeleteConfirmed}
          onClose={() => setKeyToDelete(null)}
          confirmText={t('delete')}
          confirmColor="error"
        />
      ) : null}
      {keyToAuthorize ? (
        <ConfirmationDialog
          title={t('authorize_ssh_key')}
          description={t('authorize_ssh_key_description')}
          onConfirm={handleAuthorizeConfirmed}
          onClose={() => setKeyToAuthorize(null)}
        />
      ) : null}
      {keyToUnauthorize ? (
        <ConfirmationDialog
          title={t('unauthorize_ssh_key')}
          description={t('unauthorize_ssh_key_description')}
          onConfirm={handleUnauthorizeConfirmed}
          onClose={() => setKeyToUnauthorize(null)}
          confirmColor="error"
          confirmText={t('unauthorize')}
        />
      ) : null}
      {isImportDialogOpen ? (
        <ImportSSHKeyDialog
          siteId={siteId}
          onSave={refetch}
          onClose={() => setIsImportDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
