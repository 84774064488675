import { useTheme } from '@emotion/react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useCliCommand } from 'api/cli';
import { useSdk } from 'component/hooks/useSdk';
import { BaseDialog } from 'component/new_design/base/dialogs/BaseDialog';
import { Trans, useTranslation } from 'react-i18next';
import { ReactTerminal, TerminalContextProvider } from 'react-terminal';
import { getIsWpSiteControl } from 'utils';
import { isInIframe } from 'utils/iframe';

interface WPCLIDialogProps {
  readonly onClose: () => unknown;
  readonly siteId: string;
}

export const WPCLIDialog = ({ onClose, siteId }: WPCLIDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sdk = useSdk();
  const isWpSiteControl = getIsWpSiteControl();
  const isIframe = isInIframe();

  const cliCommand = useCliCommand(siteId);

  const commands = {
    wp: async (command: string) => {
      const splitCommand = command.split(' ');

      const allParams = splitCommand.filter(part => part.startsWith('--')).join(' ');

      const allValues = splitCommand
        .slice(2)
        .filter(part => !part.startsWith('--'))
        .join(' ');

      try {
        const data = await cliCommand.mutateAsync({
          command: splitCommand[0],
          action: splitCommand[1],
          value: allValues || undefined,
          params: allParams || undefined,
        });

        const parsedResponse = JSON.parse(data.data.result.response);

        if (parsedResponse.errors) {
          return parsedResponse.errors;
        }

        return <div style={{ whiteSpace: 'pre-wrap' }}>{parsedResponse.data}</div>;
      } catch (e) {
        return 'There was an error with the command provided. Please use `wp help` for a list of all available commands.';
      }
    },
  };

  let descriptionKey = 'wp_cli_modal_description';
  if (isIframe) {
    descriptionKey = 'wp_cli_iframe_modal_description';
  } else if (isWpSiteControl) {
    descriptionKey = 'wp_cli_site_control_modal_description';
  }

  return (
    <BaseDialog
      open
      onClose={onClose}
      title={
        <Stack>
          <Typography variant="body1" fontWeight={600} mb={2}>
            {t('wp_cli_title')}
          </Typography>
          <Typography variant="caption" color="greys.500">
            <Trans
              i18nKey={descriptionKey}
              components={[
                <Link key="supportLink" href="https://support.rocket.net" target="_blank" />,
                <Link
                  key="developerLink"
                  href="https://developer.wordpress.org/cli/commands/"
                  target="_blank"
                />,
              ]}
            />
          </Typography>
          {!!sdk?.wpCliCustomDescription && (
            <Typography variant="caption" color="greys.500">
              {sdk.wpCliCustomDescription}
            </Typography>
          )}
        </Stack>
      }
      maxWidth="lg"
      PaperProps={{ sx: { height: '80vh' } }}
      content={
        <Box
          sx={{
            '[class*=index_editor]': {
              // This is copied from the `code` Typography variant in the theme
              fontFamily: 'Roboto Mono,monospace',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
            },
            '[class*=index_caretAfter]': {
              height: '0.875rem !important',
              width: '0.5rem !important',
            },
            height: '100%',
          }}
        >
          <TerminalContextProvider>
            <ReactTerminal
              themes={{
                main: {
                  themeBGColor: (theme as any).palette.greys[100],
                  themeToolbarColor: (theme as any).palette.greys[100],
                  themeColor: (theme as any).palette.greys[900],
                  themePromptColor: (theme as any).palette.oranges[500],
                },
              }}
              theme="main"
              showControlBar={false}
              commands={commands}
              welcomeMessage={<div>{t('wp_cli_welcome_message')}</div>}
            />
          </TerminalContextProvider>
        </Box>
      }
    />
  );
};
